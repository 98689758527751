import React, { useEffect, useRef } from "react"
import { useLocation } from "@reach/router"
import Countdown from "react-countdown"
import {
  getActionByPage,
  getActionByType,
  getCodeFromType,
  getCodes,
  getSectionFromCode,
} from "../../../../helpers/helper.actions"
import { connect } from "react-redux"
import OwlCarousel from "react-owl-carousel3"
import { informCustomEvent } from "../../../../helpers/helper.analytics"
import ROUTES from "../../../../../routes.js"
import { useGetPushQuery } from "../../../../redux/mediacore/push"
import { graphql, useStaticQuery } from "gatsby"

const isSSR = typeof window === "undefined"

const BannerTOP = ({ page }) => {
  if (isSSR) {
    return null
  }

  const { realEstate } = useStaticQuery(graphql`
    query {
      realEstate {
        env {
          CLIENTID
        }
      }
    }
  `)

  const { env } = realEstate
  const { data: allPushData } = useGetPushQuery(env.CLIENTID)

  const location = useLocation()
  const slider = useRef()

  console.log(allPushData)

  const slider_action = getActionByPage(
    getActionByType(
      allPushData,
      getCodeFromType("Slider de Imagenes Encabezado")
    ),
    page
  )
  const image_action = getActionByPage(
    getActionByType(allPushData, getCodeFromType("Imagen Encabezado")),
    page
  )
  const video_action = getActionByPage(
    getActionByType(allPushData, getCodeFromType("Video Encabezado")),
    page
  )

  console.log(video_action)

  const getSize = () => !isSSR && (screen.width < 992 ? "mobile" : "desktop")
  // const getSize = 'desktop'

  const goTo = () => {
    if (video_action[0].url_destiny) {
      informCustomEvent(
        "PUSH_" +
          getCodes(video_action[0]?.type, video_action[0]?.section) +
          "_" +
          video_action[0]?.name
      )
      window.open(
        video_action[0].url_destiny + video_action[0].text_utm,
        "_blank",
        "noopener,noreferrer"
      )
    }
  }

  useEffect(() => {
    if (video_action.length > 0) {
      informCustomEvent(
        "SHOW_" +
          getCodes(video_action[0]?.type, video_action[0]?.section) +
          "_" +
          video_action[0]?.name
      )
    }
    if (image_action.length > 0) {
      informCustomEvent(
        "SHOW_" +
          getCodes(image_action[0]?.type, image_action[0]?.section) +
          "_" +
          image_action[0]?.name
      )
    }
    if (slider_action.length > 0) {
      informCustomEvent(
        "SHOW_" +
          getCodes(slider_action[0]?.type, slider_action[0]?.section) +
          "_" +
          slider_action[0]?.name
      )
    }
  }, [])

  // console.log(("PUSH_" + getCodes(video_action[0]?.type,video_action[0]?.section)))

  console.log(video_action)

  return slider_action.length > 0 ||
    video_action.length > 0 ||
    image_action.length > 0 ? ( //Caso Actions video/slider
    <>
      {slider_action.length > 0 && (
        <>
          <div className="full-slider" id="section-galery-home">
            <div id="dev-secuencial">
              <div className="slider" id="dev-gallery">
                <OwlCarousel
                  items={1}
                  ref={slider}
                  margin={0}
                  nav={false}
                  dots={true}
                  loop={true}
                  rewind={true}
                  autoplay={true}
                  autoplayTimeout={slider_action[0]?.transition_time * 1000}
                  id="prop-gallery"
                >
                  {slider_action[0].files.map((file, index) => (
                    <div
                      className="item banner-main banner-ficha emprendimiento"
                      key={index}
                    >
                      <img
                        src={file.file}
                        className="object-cover object-center cursor-pointer"
                      />
                      <div className="info-banner position-absolute">
                        <div className="info row align-items-center text-center justify-content-center">
                          <div className="col-8 col-lg-12">
                            <a
                              target={"_blank"}
                              onClick={() =>
                                informCustomEvent(
                                  "PUSH_" +
                                    getCodes(
                                      slider_action[0].type,
                                      slider_action[0].section
                                    ) +
                                    "_" +
                                    slider_action[0]?.name
                                )
                              }
                              href={
                                file.url_destiny + slider_action[0].text_utm
                              }
                              className={
                                "link-gallery " +
                                (!file.url_destiny && "d-none")
                              }
                            ></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </OwlCarousel>
                <div className="arrows-owl">
                  <div className="arrow" onClick={() => slider.current.prev()}>
                    <div className="next">
                      <i className="icon-arrow"> </i>
                    </div>
                  </div>
                  <div className="arrow " onClick={() => slider.current.next()}>
                    <div className="next">
                      <i className="icon-arrow"> </i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <Searcher /> */}
          </div>
        </>
      )}
      {video_action.length > 0 && (
        <>
          <div
            className={
              "banner-main " +
              (video_action[0].url_destiny === ""
                ? "cursor-default"
                : "cursor-pointer")
            }
            onClick={() => goTo()}
          >
            {/* { getSize() &&  */}
            {
              <video loop autoPlay muted>
                <source
                  src={
                    video_action[0]?.files?.filter(
                      video => video.device === getSize()
                    )?.[0]?.file ??
                    video_action[0]?.files?.filter(
                      video => video.device === "desktop"
                    )?.[0]?.file
                  }
                  type="video/mp4"
                />
              </video>
            }
            {/* <Searcher /> */}
          </div>
        </>
      )}
      {image_action.length > 0 && (
        <div
          id="home-main"
          onClick={() =>
            informCustomEvent(
              "PUSH_" +
                getCodes(image_action[0]?.type, image_action[0].section) +
                "_" +
                image_action[0]?.name
            ) + image_action[0].url_destiny
              ? window.open(
                  image_action[0].url_destiny,
                  "_blank",
                  "noopener,noreferrer"
                )
              : ""
          }
          class={
            "banner-main " +
            (image_action.length > 0 ? " isImageFeatured " : "") +
            (image_action.length === 0 || image_action[0]?.url_destiny === ""
              ? "cursor-default"
              : "")
          }
          style={{
            zIndex: "9",
            backgroundImage: "url(" + (image_action[0]?.files[0].file + ")"),
          }}
        ></div>
      )}
    </>
  ) : null
}

export default connect(
  state => ({
    actions: state.actions.actions,
  }),
  null
)(BannerTOP)
